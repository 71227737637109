import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "My Wedding Website",
  "featuredImage": "./images/desktop-2B3.png",
  "coverAlt": "Michelle Tonye wedding day",
  "description": "Front End Development, design, UX, dev-ops, optimization of my award winning wedding website",
  "date": "2021-02-19",
  "dateModified": "2021-02-19",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Front End Development", "Graphic Design", "UI", "UX"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f3454fb51eb71e3b612b897e2fc7c384/42a19/desktop-2B3.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.055555555555564%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACTklEQVQozz2SyW4TSxhG+wFsp6q75vprHnp0J9hOcJooSu5FAlYINrCC938LFBZIZ/stzqfTlCBLkFM1fdLBsr5AMDR7GkwbnMieG9Va1YJAIJCkO8UO/2jWwtci56znJN9NvibwQKfIaxDjEKcC1hAHNFqekwNFBLtJgHvbSrpvsiXVkqhRAZKASLozEkdDnaLekOB48Rwk8paXCH0OWvOoboJGnOybpQ/zEIoXASgIVAxNhmRLrOrWuSxjyk4EQ73hAdiQrVFdkIfZd6Nrm2joXPRlzWPWILBTJOjOShStWPo4eL5UP1UvyM4IlIPyGid2yAq/jZ0mTnWzp8USI9Dltrxsd9fzeF7Lh/Pw/DC/bHfb/bxOPkCnBQJ2iAJZ2Sqyb5zCVrVOICtuvMLv1/i6HR8v0+0crqfhehq2y3Q9D0/nfkwiGmokrlEmxzTbNcVRrzsjsVPYKVQdm5KoCcaopqSOg79b4nnND8cwV91HnSzrM2TbGdU1U9JOE6tJckKznSB7xfbOyuzVbW/GpBx0fw/HRrZetwFwAhz0wQFv5gKv2/ryOP//tHz7cn16vDstYSk6R90HUYKYvTwVvaQumjdnq1unsZcoQdds9+Ovn59/fv/v94+PXz89bPfjWuGYZG9Jtiw5Oho+AT2Wm6V2xbzF0w/2chqMws1lzc+Py+u2Pl9nEEiQ3d9m2qhwDXKI8naCGngwzAPywCuwyfHLu17zQxM0tQJX3fWWGdmCRFYhI5HTnTe0Ojp7NkaVvAhWRK+tbAfVFcuSIX8Amo1icNqFa1kAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "My Wedding Website",
              "title": "My Wedding Website",
              "src": "/static/f3454fb51eb71e3b612b897e2fc7c384/42a19/desktop-2B3.png",
              "srcSet": ["/static/f3454fb51eb71e3b612b897e2fc7c384/53918/desktop-2B3.png 360w", "/static/f3454fb51eb71e3b612b897e2fc7c384/242a6/desktop-2B3.png 720w", "/static/f3454fb51eb71e3b612b897e2fc7c384/42a19/desktop-2B3.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`My Wedding Website`}</figcaption>{`
  `}</figure></p>
    <p>{`My fiancé, Michelle and I are getting married. I decided to make this website to celebrate our love. Optimized to the max (Lighthouse score of `}<strong parentName="p">{`90 on desktop`}</strong>{`, 72 on Mobile), PWA/installable, smooth scrolling parallax goodness.`}</p>
    <p>{`A lot of optimization was done to ensure this image heavy website feels light especially on mobile devices.`}</p>
    <p>{`Be sure to checkout the custom swipeable photo gallery developed in React.`}</p>
    <p>{`Scroll to the bottom to see the lighthouse reports.`}</p>
    <h2 {...{
      "id": "check-it-out-httpwwwmichelleandtonyecom",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#check-it-out-httpwwwmichelleandtonyecom",
        "aria-label": "check it out httpwwwmichelleandtonyecom permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`Check it out: `}<strong parentName="em"><a parentName="strong" {...{
            "href": "http://www.michelleandtonye.com/"
          }}>{`http://www.michelleandtonye.com`}</a></strong></em></h2>
    <h2 {...{
      "id": "",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#",
        "aria-label": " permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <h2 {...{
      "id": "skills-used",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#skills-used",
        "aria-label": "skills used permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Skills Used`}</h2>
    <p>{`React, Gatsby, SASS, framer motion, facebook pixel, webpack, bootstrap, purgecss, etc.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Other skills used`}</strong>{`: Optimizing website performance using webpack tools, css tools, in-built gatsby components, etc.`}</p>
    <h2 {...{
      "id": "screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#screenshots",
        "aria-label": "screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Screenshots`}</h2>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e209c6f880778fb288749ec583677a13/42a19/michelletonye.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC80lEQVQoz2WS3WtTdxjHn8bENC8nyUnOOck5v/PyO2lezmmSpm3SunZpkr5aYmwx2iASxIIKg7msbLLB2gljWByTDfoXDHYhgmBx7GKTKpOpF97oheCFXninF3rhjW2a3yPttqt94Qvfiy8fHp7nAa/XCwAeAPA5Q5y3LUcCP2qx8OWYEFwXef+6SaR1SsT1jGVe/urihavfrn79aXt1w/Hd1V9gbW0N/iePx9Pzb+QSSvDVVF7FhfE0VvMajqRFnB4y8Fgli9VhA0/MDODyyfrLxrlLHCKCZaUPBDifg/P7HMEA9w/H53H1ALj2ojdB+GeVvIZzxfjOqBXtVAe1zpn6WGe5Mdkp29Hts/URPFwtPAFAOOgLgdPpgl63G9y9vcBxgf9m9PcsLi47ASCix8LPR22CYxmtO5rTWWtmkF1s1Vjr2BQbTojdI1MjmErqLwCcYz6fd8Dj8Q653QcH3W53geMCxj5OV+UEH/Q91pTwU1nwvDdJGIuWyvoMgTWyGpugAvYno+zjYgoNIjJKBNTkMMY1CYkcRiUWQVkKosD7N5UgAGi6OqCKfiykFbQz9l6BFW0V1ViAjes8q8RFlIXe3QwVdlM0xiwzyuJqhKVMmdkJwkxN6sRJGInIbU6aABCSollbF3aqWdKdbJzrjk9UcanSj+3zp7Hd/ryz8tmFnR+uXMEvVlbQIAIS0c9yaY0Vcn37tvvkjqHwKIvBzWHqAnA5HdnSocGd6kf5bn2uysqV0sujeX37+2++fP33w0e4dfcevnn77tdr129s5Cy6XcybLEWl3aQhddNU6iZ1fjuuC0hioZvzZQoADsfA2FA/lg/lsZKjSJXw/ZJFsHWi/ujuvQfXbv32+9aft7cyACD2pygW8vu7RKpKmKQyUk1EU42ioSl/7B+FyHKA5/lTESl6SpdjLaop06Wi9UlpKFX8684W7P3bnlZXL9mzs7XmRHm6OTtXW1pYON6sHVlsHp4/2pyZmT/ZaCzVfv5pI/QBRBfuAhORmEEAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "My Wedding website screenshot",
              "title": "My Wedding website screenshot",
              "src": "/static/e209c6f880778fb288749ec583677a13/42a19/michelletonye.png",
              "srcSet": ["/static/e209c6f880778fb288749ec583677a13/53918/michelletonye.png 360w", "/static/e209c6f880778fb288749ec583677a13/242a6/michelletonye.png 720w", "/static/e209c6f880778fb288749ec583677a13/42a19/michelletonye.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`My Wedding website screenshot`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fd35e7d4f6b53344f33ae399a60c4c80/42a19/desktop-2B1.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.055555555555564%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACwElEQVQozx3QW2+bVgAAYB6nVVt9N9fDzXAAc8fHGAwGfMMmNvEljuu5VRY1TdpNSjVLfZiqdJq0lz3uH0+b9P2CD8tdsHDACgm7UDqmxm2qB2prZFK7SHGl+k2sPJRBOYSHzFj4IoL1UG3liC1jeDvuYlOHmbts7nKrvvhu7p6njsm9ji1wyExLrPuwfVf4N2Pr3QIVA7kvVhLYejvR70t/l+lYYlCpDcYON/OEvCdMbWDxFQSbVwGcuaCwyF8O0+McnRe9sctOdPI9Er7cDH89pmWsYIkFUouNDCazuaknTF2+y1Z0vrpN9Msu+P3x9ttvD1+eTu+vR4kNAg3f+J3Lh83jeVZmOhZqVKQzw/8lJpuaoEN8L1I/PK/9l2Py8nz39fn+2+Xhbh0jlbD5Wqrgnx/355ssHcgYgjhSiIFGBzoIu/QcSYHFWip12UZ/rfynw+z+WBxn/evI8FRKF+ohxD9/OLzZZn0TYLbUdmWip9K+RiMJ3028n0/5pgj/+Lj/WqCTx2+vR0+n+ToxBy4betLUVz/d7346zCaRgSENIA30VMZTqL5KuzJB1r6jycpJpS4SYRKv8eYrlW/1NNrUGudN8nBcPJ2vPp5Xn96uMUPEbZmyZNKUSFumbJnkyB8FtvHGEf92OIOpcVRVFdoZUhIEi5Gd++o+sfeJvYlNTGBqkGtCvm1KpKswHqRZsgLoeiziLxYHqSpHVztMrf9fCpv5auLJ68hcxXYeGJjI1EXQEEFDEdqQbymgzlFVUcCXpvDP1WCKFFsiHEjlA23qSfOenPtqOXLWaW8R21gHNFmqylNVyDVFumpIxDgwAkvYWvyfq3A/slOkjYdW0IOxxRdBdxnqRWQtY2fU72IdusZRFY6s8GSlA+q7ZVROEOqC1O1ch0Y5NHfLoe9IMtsoEFwH3UVg5IEx6WtDR/4X5TqiXuQIpuYAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "My Wedding website screenshot",
              "title": "My Wedding website screenshot",
              "src": "/static/fd35e7d4f6b53344f33ae399a60c4c80/42a19/desktop-2B1.png",
              "srcSet": ["/static/fd35e7d4f6b53344f33ae399a60c4c80/53918/desktop-2B1.png 360w", "/static/fd35e7d4f6b53344f33ae399a60c4c80/242a6/desktop-2B1.png 720w", "/static/fd35e7d4f6b53344f33ae399a60c4c80/42a19/desktop-2B1.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`My Wedding website screenshot`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/67542ceae29f20a287fac0b8300aca81/42a19/desktop-2B2.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.055555555555564%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACHklEQVQoz1WT2U7cMBiF8wDEW2LH9u94yToTJxOGKYygYmkligqVKtHe9AX6/o/QJlBUpHPhC3+/fY6PExBIr4ICBSAOqBLIKeoUNYp0lvWW9S5zmoAk+t/mFyVbnwUgiqetIfs2P+sLr/Ghh4sNhDI/9Gqu+b6Vu5pXJlM8lRzVhnaWSZ4mAYiRuJRo8nRwZHBkXBddSTqDR4d3FYuORkc2JYYCKY6swl4TxVGiBVIClRIPjo6exlUrT3uDv3+ePh3sxYZPIWsBB50u/Ios14bi1YAp0FiLfVfE9eQx5Lsqe77f/f5193y/6w2qAXuVKoEkTxVP38GKIwcsNsXciujZ+Wh/fLt5vNx8vWo+7spGo0qj1mAnUWVzt/pP4L/0FE+dwr3LYiXOd+EwhZ9PVw/X83FylU4rQIt5S/ad3AYO4j2sBfIKO4UaQxurjnN3MYfG5XOnvDzpXmBHB0uiJdHRxCqqRfoGm2Lh54YPlp5uytiWsdZtSYJGMWSv5DpigYPOHDCQVK8dWBoi09uz6vF6PA56CtnWZV6lvaWxkb3P57YYPZsCi44knZN9UFAQqxisPetL8nC1fbwZn26mL5f94FhQqVNLokbi1ubbujhObgx5UhsRgAfDW2CDlx7YpsSX0Tzdjncf6mOESqOg0Vs0Mj8xEveh6DxPKstrV1jINr6YGm2BeSDRs9NG7FvRAa70YkS9fQHxOujvU/0BRG1Z+35ELvwAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "My Wedding website screenshot",
              "title": "My Wedding website screenshot",
              "src": "/static/67542ceae29f20a287fac0b8300aca81/42a19/desktop-2B2.png",
              "srcSet": ["/static/67542ceae29f20a287fac0b8300aca81/53918/desktop-2B2.png 360w", "/static/67542ceae29f20a287fac0b8300aca81/242a6/desktop-2B2.png 720w", "/static/67542ceae29f20a287fac0b8300aca81/42a19/desktop-2B2.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`My Wedding website screenshot`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d3a9f99738a45b32d4bc3445cb84fbd9/42a19/desktop-2B4.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.611111111111114%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABl0lEQVQoz32SUW/aMBCA85rETnDss89xQoApHaVJgG4sqAIEo4xSKnXV3qf9/1+xJSQRlaZK92B9d591d7alBNFAlSCXCJWvpX85INBQeVWB9BColl5XhkAlJ5YGss3DltJhopIYELxhLFF6ScS0pINYhsrvG9HJ2YgrQSwUZNxnHZXcldytztzpCyfmjmpJw+tIdNWdVdN3puSuYPYwZKsUVyl+MgEETpdqKmvLqgdo5L7hgxgGsUwimKTx2+bLr819MR5EyGoOBntXPbYyCgqBMy/G2/W3h3K+2yx36/L3z+Of18N+sywX04dyvl0vs9uRYLaudsGVoJbkBLiLgghmz/Kb89P+sFu9nA7n0+P5tD8dvx9/7J4O25fnx7fX54uMQI2qZzbKy4dKVat3Dfay21Fxl86ytJxPFnm6mH7+WtwUd+n9dDzN0+vXqto2ys9GKOvXRiAQ2JzZmjsT7ZVJMIv8mfFl4AhmA7M7s5Hrn+BfU6z+DAVB4LLVhpDriHSvXdj7RHNFy/+bTSL+kfxxhMr/J/8F3stImkyzuKMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "My Wedding website screenshot",
              "title": "My Wedding website screenshot",
              "src": "/static/d3a9f99738a45b32d4bc3445cb84fbd9/42a19/desktop-2B4.png",
              "srcSet": ["/static/d3a9f99738a45b32d4bc3445cb84fbd9/53918/desktop-2B4.png 360w", "/static/d3a9f99738a45b32d4bc3445cb84fbd9/242a6/desktop-2B4.png 720w", "/static/d3a9f99738a45b32d4bc3445cb84fbd9/42a19/desktop-2B4.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`My Wedding website screenshot`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/01251a6a818511465bd6348b4eee2a6e/42a19/desktop-2B5.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.055555555555564%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACq0lEQVQoz22Sy08TURTGJ4BCO525dzpz75376LQdKLTT0tJSXn1YKFAi8jI8K1BQSnjEBDWRjRiMYachcSEaNyYsZWNYsmRrYli5ZsPahX+ApiExJJr8Vt85X86XL0cSBPB/YAQw7KFeN9YVYf5n4RrJCRo21yi+oZqAI6Uv0fzuYDSbElRXhQn/TilWW3xam1+nWJVsrlkUsuuDWK1BgIU8/V0dr/dmhnssqqv8hplhEGAwyDWGgXRDVW0LR0KWRbVQkFpcZ1izBPELb7NlBAQK+AgjGje9jECG1VpsQYAwgTChMKGvFgEahqppMgRuilWGFIYVosuCKAI1Mr3Bh29xLF8nlYQJTAyQVyaaG2lydytazVn7ywOHz8vZuB2LtbTYIp0ImSKI0hsi+0yNbJuBzgBTGIGSz4QIuOPck7VBCLkSXJlz0HJv88thpzzUt7m1lkyntjaX9GAfWvye2L9q3f0ZzD0O4AZGvBIjMI7lQa6U/HAgjKJUSROZ1NdFlfpCOlJdX5ke6n9RnXMy9/LvL+e/Xm2cXnWMLhK1TtCaGYSxJ2+4ktDdy9UgbGpx1aWBq8hdXU4gl+tKx0LFTCpfyJycnZ9/u/j0+Xh8dFAHjT6qSZxAn+5pR/L9OH1TSWVDaLnXHg2C/fF4ZbJQWXkwVCqWK+XiQM+X46MPH48O3x7cLRUM2FQz19qmuoG07hDaW810NhtjSV4M69mwcae3vbI8vz5Wmp2dCkej2dJsR26mp7/cFon5We0LJap7ejqd3e2l6sxIfqgQ8RsZ3jTZ7u0Oy4mY1ZmKqR53f77Dbh+AE2fRJz/UqQvDWbBZEyOaRA2P44TGJ0aq5fGdnWqyzdfqvV1qRdMpku92Hj1cSiQTW+sL+cm18KvLiZNfi6e/45NPTVUSVP8DUNiKxZSUxSQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "My Wedding website screenshot",
              "title": "My Wedding website screenshot",
              "src": "/static/01251a6a818511465bd6348b4eee2a6e/42a19/desktop-2B5.png",
              "srcSet": ["/static/01251a6a818511465bd6348b4eee2a6e/53918/desktop-2B5.png 360w", "/static/01251a6a818511465bd6348b4eee2a6e/242a6/desktop-2B5.png 720w", "/static/01251a6a818511465bd6348b4eee2a6e/42a19/desktop-2B5.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`My Wedding website screenshot`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9be0f0720cf33ba70e5e85ec83907ce8/42a19/desktop-2B7.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.055555555555564%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACpElEQVQoz22Q2UsVURzH5869d+6sZ86ZM3PO7N7ruJQtprhgC6UVlhASLWZkRJqFUIqWhZGV0IJoFqkpQRotaNw0E/GlHi60EkXQcw+9BZH0J9Sg6Evwffx9vr/v98tAPvofJVgQj0hRRmRDSSyjJthlLZ8xmhBDQhQKMSjEkBjHMkeQ7Jl4bV6qalPJlvKizWXFFSWFrgEplBxD9U0MhWiI8FFmyUaIASEOhAQQ+R3VlXeH+qbSTxf+LPxa+P3u/ds3mdfrC/IdagS+vSrb1cT40udlGMoCBrJFcHPTkfEHw7PTk7OzUy+ejY0P9472d+ckfZeSwHNyfGsFBlxYQ5M4HUoO0ZOu2XP53OjI7bn0o6O11Q17d6Ufj1zranUJJhoKPDvwiJpgARc2Z1wD2FiGYpwgYBOcl/LPtrf0XO36/Opld2PdQNuxzMTgnf7rvkUJUlOO5Vm2T7GFRMCxjE+hoyumKtgG1IBcWJBff2jf+QudP398z0zcm7nVNXaxqbnhAEGqpalZNvW8IOU6jiYBLhLGhlzEw4pDEIaKZ5Lujpa56clvX79M9F2a6W3PjN3YX1OlKZKNkWcZqVQSSgLgIpCPhYMhPuoiySUa0VTfoh3H6+fTTz5++vCws+15TcX904erd27VgGwbOHDNXI8aQIA8u7K2BUWP6qYeep+oq2091Tg0OHBm28Yr5YU3TzaUlm4gCLhEz3Zo4BJd4dUEi4RlGCu+iW2CPIIO7q4qWrcaUlypa/OVZW17thevyc02cdI2sxzLNwkSuTDvIqxJcZ+oDlYMKOoKlyJqQKGry5YqBFBykOTpiolkk1gmdTRkQlFEPAsXYSTGdYUngKdQQkJM5RgssIYUxRJnqCIS41QVNUWEiOrUItRGQIaJyL/OfwFy84bNlSuAMwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "My Wedding website screenshot",
              "title": "My Wedding website screenshot",
              "src": "/static/9be0f0720cf33ba70e5e85ec83907ce8/42a19/desktop-2B7.png",
              "srcSet": ["/static/9be0f0720cf33ba70e5e85ec83907ce8/53918/desktop-2B7.png 360w", "/static/9be0f0720cf33ba70e5e85ec83907ce8/242a6/desktop-2B7.png 720w", "/static/9be0f0720cf33ba70e5e85ec83907ce8/42a19/desktop-2B7.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`My Wedding website screenshot`}</figcaption>{`
  `}</figure></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
    </table>
    <p>{`|`}</p>
    {
      /* ![My Wedding website mobile screenshot](./images/mobile-2B1.png) | ![My Wedding website mobile screenshot](./images/mobile-2B2.png) | */
    }
    <h2 {...{
      "id": "lighthouse-performance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lighthouse-performance",
        "aria-label": "lighthouse performance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lighthouse performance`}</h2>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "895px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/668887b08de4cc852e96e97ef469d291/b002d/desktop-2Bfeb-2B19.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "114.44444444444446%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAAsTAAALEwEAmpwYAAACLElEQVQ4y42Sy2sUQRDG9y8LRET0LggeAx48eRBWiRcfiUm8eBL06mM3HgJhBUMSUQmaeMxBPGkgyRjXITvT3dXvrq6W2axxE8d1izrMMPOrr+qrasx37FzH3l82M0twd0k+XtdPN9TzD6a95V9sutama2/5xU/+0aqZW9bzHTucjXNz8sICnF+QZ2blxD059QRutOD6M2i29a1FO922zZaefmkuPWQTt8vJWZic+ZONnGMPquwyzBnuHeK3HHdy3M3TRpZ//N7bzelrF7Midhl2WfjJ8DgbqS4oUUq0Y7PM/fj9WhMNonQqIxFilAAaJOcAUsVIRAN++M8aZQCw1jrnrPPakVRIKR1ke1MXzzavXpYg+iWqWjWwUtp7h4MgH2KktJ8d3Lx25cGdplJyFGydDSGkQf+YCJWhEnx/+JMz/w0LYEKISCmgFyAEF0QUgkMMVa3RsDamKFlZcs4FK3lRPTFndI3bp5fUHybb31t93VlbefX+zcrG27V36ytfPm8ffx2lzFmplAohxBgTUaTktEHv/69cta21UspaO/A7YrA2npz238qcFUUhAZytbMfKqOpMxlUuj+wSwhjjva96HxMGgF6vB/3Aum5HwdVdWnvkba3giFUlYNpY379uV40caWw4JX7IlFLOOe8cVnRff1zDjNUmWJ8iJamUsTZgijGNaZgsGJfSICIXoihLpTXW0TWwlKpkEgQE771zWhtj/biropRGWTwE/wJEfB4nSrMuLQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "My Wedding website lighthouse results",
              "title": "My Wedding website lighthouse results",
              "src": "/static/668887b08de4cc852e96e97ef469d291/b002d/desktop-2Bfeb-2B19.png",
              "srcSet": ["/static/668887b08de4cc852e96e97ef469d291/53918/desktop-2Bfeb-2B19.png 360w", "/static/668887b08de4cc852e96e97ef469d291/242a6/desktop-2Bfeb-2B19.png 720w", "/static/668887b08de4cc852e96e97ef469d291/b002d/desktop-2Bfeb-2B19.png 895w"],
              "sizes": "(max-width: 895px) 100vw, 895px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`My Wedding website lighthouse results`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "856px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/bb27ff277b1d2f09bfe85215055181b6/1712d/mobile-2Bfeb-2B19.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "119.72222222222221%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAIAAAB1KUohAAAACXBIWXMAAAsTAAALEwEAmpwYAAACYElEQVQ4y5WUu09UQRSH9++wsbC1ssAQTYgFtSS0JHRq5YtNRBJEURs0QmOHhTGLJmhDgVRUIMuiITYm3LiIhl12Ze9z3jNn5ph7EcKSZXP55WSSeXxnzpwzM4Xpz2pmSb1alFMLZGqBzi7zuVX2YU3Or+v5dZW1+mNFzy6L6UUxs6SOW+HaM9L3lPRNkitPaM8jOvSaFN+Ru29JscSLJVmcUyMl/uC9GHwZXR4Leh/T3glyZIVmBHsh1AOo+VD3YbsJW3Xw9ozXcN7vlrdd8xpuq2Z29mHXh13f1Hw4sgJ2lksbWk3tf9chGkQ4vqjgHJ4w6xyAZYQSQsOEUMpsNpg6aF/ZYWdKqZRSKSWlEsoyAcZi0GqO3R6enhxhlKSRpK46wZwLpRQAGGMMOKUtIno/q9d7zt8YuNry/W6wzMjsoM5acNY4xMrmj/6L5/ovXWj8bSKitbYzTEkSx5ExYJSIoiAIAkT8s1MdHuy/OTQQhmHXsBlLCMvyg+DQgLNZR8hUbdk+QR7E833z6+j9W+OjdyYe3ns+PvJiovip9OZo9lTYOaeUauzVK2srlfLqRnmlUv7ybWP9V9VLZ7vDiEjimDLWNqQUtmOnwoJzQgilJKu2VELIJDFK5YKTOPF9nxKipTQAYC1Ym+UsB8w5D8MwjuMkSYQQWuu02jlhSmmr5ZNMWmt3qFywPKznaUw3mCVCSJ09DaWNAXBngKP9iDGmM5k0X2eBhZRcGG3QWWSMS6nAYkcPnS5JQoIgIpQbY+I49v2AUHbwznKcmfMoppQyY9LIhZBS6bzZPvhu8ugfrylHGzplx/wAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "My Wedding website lighthouse results",
              "title": "My Wedding website lighthouse results",
              "src": "/static/bb27ff277b1d2f09bfe85215055181b6/1712d/mobile-2Bfeb-2B19.png",
              "srcSet": ["/static/bb27ff277b1d2f09bfe85215055181b6/53918/mobile-2Bfeb-2B19.png 360w", "/static/bb27ff277b1d2f09bfe85215055181b6/242a6/mobile-2Bfeb-2B19.png 720w", "/static/bb27ff277b1d2f09bfe85215055181b6/1712d/mobile-2Bfeb-2B19.png 856w"],
              "sizes": "(max-width: 856px) 100vw, 856px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`My Wedding website lighthouse results`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      